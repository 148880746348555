import { Injectable } from '@angular/core';
import { HighlightRect } from './moon-pdf-viewer.component';
import { BezierPath } from 'ngx-extended-pdf-viewer';

export interface PdfSerializedAnnotation {
  color: [number, number, number];
  pageIndex: number;
  thickness: number;
  opacity: number;
  rect: number[];
  rotation: number;
  annotationType: number;
  paths?: BezierPath[];
  outlines?: number[][][];
  quadPoints?: number[];
}

@Injectable({
  providedIn: 'root'
})
export class MoonPdfViewerService {

  private moonPdfViewerRenderAllPages?: () => Promise<void>;
  private moonPdfViewerGetTextSpans?: (highlightedSpans?: boolean) => HTMLElement[];
  private moonPdfViewerFocusHighlight?: (highlight: HighlightRect) => void;
  private moonPdfViewerSearchText?: (text: string) => Promise<boolean>;
  private moonPdfViewerAnnotationFocused?: (subTaskMessageId: string) => void;
  private moonPdfViewerAnnotationCanceled?: () => Promise<void>;
  private moonPdfViewerAnnotationSaved?: () => Promise<void>;
  private moonPdfViewerSetHighlights?: (activeHighlights: HighlightRect[], nonActiveHighlights: HighlightRect[]) => Promise<void>;

  registerAnnotationFocused(annotationFocused: (subTaskMessageId: string) => void): void
  {
    this.moonPdfViewerAnnotationFocused = annotationFocused;
  }

  registerAnnotationCanceled(annotationCanceled: () => Promise<void>): void
  {
    this.moonPdfViewerAnnotationCanceled = annotationCanceled;
  }

  registerAnnotationSaved(annotationSaved: () => Promise<void>): void
  {
    this.moonPdfViewerAnnotationSaved = annotationSaved;
  }
  registerRenderAllPages(renderAllPages: () => Promise<void>): void
  {
    this.moonPdfViewerRenderAllPages = renderAllPages;
  }

  registerGetTextSpans(getTextSpans: () => HTMLElement[]): void
  {
    this.moonPdfViewerGetTextSpans = getTextSpans;
  }

  registerFocusHighlight(focusHighlight: (highlight: HighlightRect) => void): void
  {
    this.moonPdfViewerFocusHighlight = focusHighlight;
  }

  registerSearchText(searchText: (text: string) => Promise<boolean>): void
  {
    this.moonPdfViewerSearchText = searchText;
  }

  registerSetHighlights(setHighlights: (highlight: HighlightRect[], otros: HighlightRect[]) => Promise<void>): void
  {
    this.moonPdfViewerSetHighlights = setHighlights;
  }

  async renderAllPages(): Promise<void>
  {
    if (!this.moonPdfViewerRenderAllPages)
    {
      console.error('moonPdfViewerRenderAllPages is not set');
      return;
    }
    await this.moonPdfViewerRenderAllPages();
  }

  getTextSpans(highlightedSpans?: boolean): HTMLElement[]
  {
    if (!this.moonPdfViewerGetTextSpans)
    {
      console.error('moonPdfViewerGetTextSpans is not set');
      return [];
    }
    return this.moonPdfViewerGetTextSpans(highlightedSpans);
  }

  focusHighlight(highlight: HighlightRect): void
  {
    if (!this.moonPdfViewerFocusHighlight)
    {
      console.error('moonPdfViewerFocusHighlight is not set');
      return;
    }
    this.moonPdfViewerFocusHighlight(highlight);
  }

  async searchText(text: string): Promise<boolean>
  {
    if (!this.moonPdfViewerSearchText)
    {
      console.error('moonPdfViewerSearchText is not set');
      return false;
    }
    return await this.moonPdfViewerSearchText(text);
  }

  async annotationFocused(subTaskMessageId: string): Promise<void>
  {
    if (!this.moonPdfViewerAnnotationFocused)
    {
      console.error('moonPdfViewerAnnotationFocused is not set');
      return;
    }
    this.moonPdfViewerAnnotationFocused(subTaskMessageId);
  }

  async annotationCanceled(): Promise<void>
  {
    if (!this.moonPdfViewerAnnotationCanceled)
    {
      console.error('moonPdfViewerAnnotationCanceled is not set');
      return;
    }
    await this.moonPdfViewerAnnotationCanceled();
  }

  async annotationSaved(): Promise<void>
  {
    if (!this.moonPdfViewerAnnotationSaved)
    {
      console.error('moonPdfViewerAnnotationSaved is not set');
      return;
    }
    await this.moonPdfViewerAnnotationSaved();
  }

  async setHighlights(activeHighlights: HighlightRect[], nonActiveHighlights: HighlightRect[]): Promise<void>
  {
    if (!this.moonPdfViewerSetHighlights)
    {
      console.error('moonPdfViewerSetHighlights is not set');
      return;
    }
    await this.moonPdfViewerSetHighlights(activeHighlights, nonActiveHighlights);
  }

  hexToRgb(hex: string): [number, number, number] | null
  {
    hex = hex.replace(/^#/, '');
    if (!/^[0-9A-Fa-f]{3,6}$/.test(hex))
    {
        return null;
    }
    if (hex.length === 3)
    {
        hex = hex.split('').map(char => char + char).join('');
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
  }

  getRandomString(length: number = 16): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  rgbToHex(r: number, g: number, b: number): string {
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');

    return `#${hexR}${hexG}${hexB}`;
  }


  parseAdobeAnnotationToPdfAnnotation(
    subtype: string,
    rect: number[],
    quadPoints: number[],
    hexColor: string,
    thickness: number = 1,
    pageNumber: number = 1
  ): PdfSerializedAnnotation
  {
    const defaultColor = '#000000';
    const pageIndex = pageNumber === 0 ? 0 : pageNumber - 1;
    const color = hexColor ? this.hexToRgb(hexColor) : this.hexToRgb(defaultColor);

    const normalizedRect = [
      Math.min(rect[0], rect[2]),
      Math.min(rect[1], rect[3]),
      Math.max(rect[0], rect[2]),
      Math.max(rect[1], rect[3]),
    ];

    const baseAnnotation = {
      color: color,
      pageIndex: pageIndex,
      thickness: thickness,
      opacity: 1,
      rect: normalizedRect,
      rotation: 0,
    };

    if (subtype === 'shape')
    {
      // Handle shape annotaitons as 'dot'. Quadpoints must be at least 8,16,32.. etc.
      if(quadPoints.length === 2)
      {
        quadPoints = [quadPoints[0], quadPoints[1], quadPoints[0 ], quadPoints[1],
        quadPoints[0], quadPoints[1], quadPoints[0 ], quadPoints[1]];
      }
      return { ...baseAnnotation, annotationType: 15, paths: <BezierPath[]>[{ bezier: quadPoints, points: quadPoints }]};
    }
    else
    {
      return { ...baseAnnotation, annotationType: 9, outlines: [[quadPoints]], quadPoints : quadPoints };
    }
  }
}
